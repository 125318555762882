import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"

import Box from "../../../../../components/box"
import Blockquote from "../../../../../components/blockquote"
import Icon from "../../../../../components/icon"
import Paragraph from "../../../../../components/paragraph"
import Image from "../../../../../components/image"
import ImageHotspot from "../../../../../components/image-hotspot"
import ImageHotspots from "../../../../../components/image-hotspots"
import ImageHotspotsModalTemplate from "../../../../../components/image-hotspots-modal-template"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"
import Poem from "../../../../../components/poem"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })

  const data = useStaticQuery(graphql`
    query ManuskriptHeidelbergQuery {
      gedicht: file(relativePath: { eq: "kurse/manuskript-heidelberg.png" }) {
        ...largeImage
      }
      heidelberg01: file(relativePath: { eq: "kurse/heidelberg-01.jpg" }) {
        ...largeImage
      }
      heidelberg02: file(relativePath: { eq: "kurse/heidelberg-02.jpg" }) {
        ...largeImage
      }
      heidelberg03: file(relativePath: { eq: "kurse/heidelberg-03.jpg" }) {
        ...largeImage
      }
      heidelberg04: file(relativePath: { eq: "kurse/heidelberg-04.jpg" }) {
        ...largeImage
      }
      heidelberg05: file(relativePath: { eq: "kurse/heidelberg-05.jpg" }) {
        ...largeImage
      }
      heidelberg06: file(relativePath: { eq: "kurse/heidelberg-06.jpg" }) {
        ...largeImage
      }
    }
  `)

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/02-reiseerinnerung/bildelemente" />
      }
      navigation={navigation}
    >
      <Seo title="Das Manuskript erforschen" />
      <Stack>
        <Heading as="h2" level={2}>
          Das Manuskript erforschen
        </Heading>
        <TaskSidebar
          main={
            <ImageHotspots
              image={
                <Image
                  image={data.gedicht.childImageSharp.gatsbyImageData}
                  caption="Hölderlins Entwurf zu seinem Gedicht ›Heidelberg‹ aus dem Kurpfälzischen Museum Heidelberg."
                  alt="Handschriftliches Manuskript von Heidelberg"
                />
              }
            >
              <ImageHotspot x={35} y={20} name="heidelberg-01">
                <ImageHotspotsModalTemplate
                  title="Heidelberg"
                  image={
                    <Image
                      image={data.heidelberg01.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  }
                >
                  <Paragraph>
                    Hölderlin beginnt sein Gedicht mit dem Namen der Stadt, um
                    die es gehen soll: Heidelberg. Danach macht er erst einmal
                    einen Punkt. Er muss nachdenken. Was verbindet ihn mit
                    dieser Stadt? Dann setzt er zur ersten Zeile an...
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={40} y={30} name="heidelberg-02">
                <ImageHotspotsModalTemplate
                  title="Die ersten Worte"
                  image={
                    <Image
                      image={data.heidelberg02.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  }
                >
                  <Paragraph>
                    Einen Gedichtanfang hat Hölderlin schnell gefunden. Die
                    Worte kommen fast wie von selbst aufs Papier:
                  </Paragraph>
                  <Blockquote>
                    <Poem>
                      <p>Lange lieb ich dich schon,</p>
                      <p>Du der Vaterlandsstädte</p>
                      <p>Ländlichschönste</p>
                    </Poem>
                  </Blockquote>
                  <Paragraph>
                    Doch schon weiß er nicht mehr weiter. Was hat er von
                    Heidelberg eigentlich noch in Erinnerung? Und wie passt das
                    in die Form eines Gedichts? Hölderlin legt seinen Stift zur
                    Seite. Schluss für heute.
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={60} y={27} name="heidelberg-03">
                <ImageHotspotsModalTemplate
                  title="Vaterland und Mutterstadt?"
                  image={
                    <Image
                      image={data.heidelberg03.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  }
                >
                  <Paragraph>
                    Erst später kommt Hölderlin ein Gedanke: Wenn es in dem
                    Gedicht einen Vater gibt, muss es doch auch eine Mutter dazu
                    geben. Er überlegt: Vaterlandsstädte und... Mutterstadt?
                    Aber nein, so etwas gibt es nicht. Hölderlin streicht das
                    Wort wieder durch. Doch der Gedanke lässt ihn nicht los.
                    Später schreibt er:{" "}
                  </Paragraph>
                  <Paragraph>
                    »Lange lieb ich dich schon, möchte dich mir zur Lust gern
                    Mutter nennen, möchte dir gern schenken ein kunstlos Lied«
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={55} y={42} name="heidelberg-04">
                <ImageHotspotsModalTemplate
                  title="Vom Fluss umschlungen"
                  image={
                    <Image
                      image={data.heidelberg04.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  }
                >
                  <Paragraph>
                    In der 2. Strophe denkt Hölderlin an den Neckar, der durch
                    die Stadt fließt. Er setzt an:
                  </Paragraph>
                  <Paragraph>
                    »Zwar dein Nekar umfließt auch des bescheidenen Städtchens
                    Hügel...«.
                  </Paragraph>
                  <Paragraph>
                    Doch dann muss Hölderlin noch einmal streichen und
                    korrigieren. Ihm kommt eine bessere Idee: Der Neckar
                    umfließt die Hügel nicht nur, er »schlingt« richtig sich um
                    sie herum.
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={65} y={45} name="heidelberg-05">
                <ImageHotspotsModalTemplate
                  title="Ferien-Erinnerung"
                  image={
                    <Image
                      image={data.heidelberg05.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  }
                >
                  <Paragraph>
                    An dieser Stelle hat Hölderlin noch eine Ergänzung zwischen
                    die beiden Zeilen eingeschoben. Er erinnert sich an seine
                    Schulferien, in denen er zum ersten Mal durch Heidelberg
                    kam. Und so fügt er an den Satz »Zwar dein Nekar umschlingt
                    auch [das] verborgne Städtchen« den Zusatz hinzu: »wo mich
                    der Wald weise der Schul entzog«.
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={87} y={87} name="heidelberg-06">
                <ImageHotspotsModalTemplate
                  title="Vom Tönen zum Rauschen"
                  image={
                    <Image
                      image={data.heidelberg06.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  }
                >
                  <Paragraph>
                    Zuletzt beschreibt Hölderlin die große Brücke, die über den
                    Fluss in die Stadt hineinführt. Laut war es dort. Und so
                    schreibt er über die Brücke, »die von Wagen und Menschen
                    tönt«.
                  </Paragraph>
                  <Paragraph>
                    Aber war es nicht eher ein Rauschen, wie in einer großen
                    Stadt, in der man gar keine einzelnen Töne mehr wahrnehmen
                    kann? Ja, rauschen, das trifft es eher.
                  </Paragraph>
                  <Paragraph>
                    Und nun ist Hölderlin aber wirklich in der untersten Ecke
                    des Blatts angekommen. Er muss das Blatt wenden und auf der
                    Rückseite weiterschreiben...
                  </Paragraph>
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
            </ImageHotspots>
          }
          sidebar={
            <Note variant="task">
              <>
                Was kann man aus dieser Handschrift erfahren? Sieh dir das
                Manuskript genauer an und inspiziere die mit dem
                <Box
                  as="span"
                  display="inline-flex"
                  ml={1}
                  bg="primary"
                  color="background"
                  borderRadius="full"
                >
                  <Icon size={4} icon="plus" />
                </Box>{" "}
                Zeichen gekennzeichneten Details.
              </>
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
